@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,600;1,500&display=swap'); /* font-family: 'Jost', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap'); /* font-family: 'Rubik', sans-serif; */

#partnership-component {
}

#partnership-component #partnership-benefits {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 24px;
}

#partnership-component #partnership-benefits .col-md-5 {
    padding: 20px;
    text-align: center;
    margin: 10px;
    border-radius: 10px;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

#partnership-component #partnership-benefits .col-md-5:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

#partnership-component #partnership-benefits .col-md-5 .icons {
    font-size: 70px;
    margin-bottom: 20px;
}

#partnership-component #partnership-benefits .col-md-5 .icons #rocket {
    color: rgb(7, 93, 52);
    /* add outer glow effect acording to the design */
    text-shadow: 0 0 10px rgba(7, 93, 52, 0.5);


}

#partnership-component #partnership-benefits .col-md-5 .icons #tools {
    color: #00b5e2;
}

#partnership-component #partnership-benefits .col-md-5 .icons #lightning {
    color: #f7b500;
}

#partnership-component #partnership-benefits .col-md-5 .icons #graph {
    color: #ff6b6b;
}

#partnership-component #partnership-benefits .col-md-5 h5 {
    font-size: 30px;
    font-family: 'Jost', sans-serif;
    font-weight: 1000;
    color: #000000;
    margin-bottom: 15px;
}

#partnership-component #partnership-benefits .col-md-5 p {
    font-size: 20px;
    font-family: 'Rubik', sans-serif;
    font-weight: 300;
    color: #000000;
}

/* Media Queries */
@media (max-width: 1200px) {
    #partnership-component #partnership-benefits .col-md-5 {
        margin: 20px 5px;
    }
}

@media (max-width: 992px) {
    #partnership-component #partnership-benefits .col-md-5 {
        flex-basis: calc(50% - 20px);
    }
}

@media (max-width: 768px) {
    #partnership-component #partnership-benefits .col-md-5 {
        flex-basis: calc(50% - 20px);
    }

    #partnership-component #partnership-benefits .col-md-5 .icons {
        font-size: 60px;
    }

    #partnership-component #partnership-benefits .col-md-5 h5 {
        font-size: 24px;
    }

    #partnership-component #partnership-benefits .col-md-5 p {
        font-size: 18px;
    }
}

@media (max-width: 576px) {
    #partnership-component #partnership-benefits .col-md-5 {
        flex-basis: calc(100% - 20px);
    }
}
