@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,600;1,500&display=swap'); /* font-family: 'Jost', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap'); /* font-family: 'Rubik', sans-serif; */

/* Home Component */
#home-component1 {
    background-image: url("../Images/mainbg.webp");
    height: 600px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: #000000;
}

#home-component1 #home-component1-text1 {
    font-family: 'Jost', sans-serif;
    font-size: 80px;
    font-weight: 600;
    color: white;
    padding-top: 120px;
    text-shadow: #000000 0px 0px 10px;
    animation: vibrate-1 7s infinite;
}

@keyframes vibrate-1 {
    0%, 100% { transform: translate(0, 0) rotate(0deg); }
    10%, 90% { transform: translate(-2px, -2px) rotate(-1deg); }
    20%, 80% { transform: translate(-2px, 2px) rotate(1deg); }
    30%, 70% { transform: translate(2px, -2px) rotate(0deg); }
    40%, 60% { transform: translate(2px, 2px) rotate(1deg); }
    50% { transform: translate(-2px, 2px) rotate(-1deg); }
}

#home-component1 #home-component1-text2 {
    font-size: 25px;
    font-family: 'Rubik', sans-serif;
    font-weight: 300;
    color: white;
    padding-top: 20px;
    text-shadow: #000000 1px 1px 20px;
}

/* Home Services */
#home-services {
    background-color: #000000;
    padding: 20px 0 50px 0;
}

#home-services #component1 {
    padding: 0 20px;
}

#home-services #component1 #text1 {
    font-family: 'Jost', sans-serif;
    font-size: 40px;
    font-weight: 600;
    color: rgb(197, 20, 20);
    padding-top: 10px;
    text-align: center;
}

#home-services #component1 #row1 .div1 {
    width: 335px;
    padding: 20px;
    border: 1px solid white;
    border-radius: 10px;
    background-color: #000000;
    color: white;
    text-align: center;
    margin: 10px;
}

#home-services #component1 #row1 .icon {
    font-size: 100px;
    background-image: linear-gradient(45deg, #7426c7, #c726ae);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

#home-services #component1 #row1 .btn1 {
    border: 2px solid;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    margin-top: 10px;
    background-image: linear-gradient(45deg, #7426c7, #c726ae);
    border-color: transparent;
    color: white;
    transition: background-color 1s, color 1s;
}

#home-services #component1 #row1 .btn1:hover {
    background-image: none;
    background-color: #ffffff;
    color: #7426c7;
    border-color: #7426c7;
}

/* INFO TAB */
#home-infotab {
    padding: 10px 0;
}

#home-infotab .row #col2 #infotab-text1 {
    font-size: 55px;
    font-family: 'Jost', sans-serif;
    font-weight: 1000;
    text-align: right;
    background-image: url("../Images/text-gif.gif");
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
}

#home-infotab .row #col2 #infotab-text2 {
    font-size: 25px;
    font-family: 'Rubik', sans-serif;
    font-weight: 300;
    text-align: right;
}

#home-infotab .row #col2 #infotab-button {
    border: 2px solid;
    padding: 13px 28px;
    font-size: 18px;
    border-radius: 5px;
    margin-top: 10px;
    background-image: linear-gradient(45deg, #7426c7, #c726ae);
    border-color: transparent;
    color: white;
    transition: background-color 0.5s, color 0.5s;
}

#home-infotab .row #col2 #infotab-button:hover {
    background-image: none;
    background-color: #ffffff;
    color: #7426c7;
    border-color: #7426c7;
}

/* LOCATIONS */
#locations {
    padding: 20px 0;
    /* border: 2px solid #7426c7; */
    transition: 0.3s;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../Images/colombo-sri-lanka.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: fit-content;
}

#locations #locations-text {
    font-size: 54px;
    font-weight: 600;
    text-align: center;
    color: white;
}

#locations .col-md-10 {
    height: auto;
    width: 700px;
    margin: 5px 20px;
    border-radius: 20px;
}

#locations .col-md-10 h4 {
    font-family: 'Jost', sans-serif;
    font-size: 40px;
    font-weight: 600;
    color: white;
    text-align: center;
    text-shadow: #000000 1px 1px 20px;
}

#locations .col-md-10 h2 {
    font-family: 'Jost', sans-serif;
    font-size: 30px;
    font-weight: 300;
    color: white;
    text-align: center;
    padding-top: 5px;
}

#locations .col-md-10 p {
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    font-weight: 300;
    color: white;
    text-align: center;
    padding-top: 5px;
}

#locations #locations-card1, #locations #locations-card2 {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}

#locations #locations-card1 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.63));
}

#locations #locations-card2 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../Images/London-UK.jpeg");
}

/* Media Queries */
/* Home Component */
@media only screen and (max-width: 600px) {
    #home-component1 {
        height: 600px;
    }

    #home-component1 #home-component1-text1 {
        padding-top: 150px;
        font-size: 45px;
    }

    #home-component1 #home-component1-text2 {
        font-size: 16px;
        padding-top: 20px;
    }
}

@media only screen and (max-width: 372px) {
    #home-component1 {
        height: 650px;
    }

    #home-component1 #home-component1-text1 {
        padding-top: 100px;
        font-size: 45px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 780px) {
    #home-component1 {
        height: 700px;
    }

    #home-component1 #home-component1-text1 {
        padding-top: 100px;
        font-size: 45px;
    }
}

/* Home Services */
@media only screen and (max-width: 600px) {
    #home-services #component1 .div1 {
        width: auto;
        margin: 10px 0;
    }

    #home-services #component1 #text1 {
        font-size: 30px;
    }

    #home-services #component1 .btn1 {
        font-size: 14px;
    }
}

/* INFO TAB */
@media only screen and (max-width: 600px) {
    #home-infotab .row #col2 #infotab-text1 {
        font-size: 40px;
    }

    #home-infotab .row #col2 #infotab-text2 {
        font-size: 20px;
    }

    #home-infotab .row #col2 #infotab-button {
        padding: 10px 20px;
        font-size: 14px;
    }
}

/* LOCATIONS */
@media only screen and (max-width: 600px) {
    #locations #locations-text {
        font-size: 30px;
    }

    #locations .col-md-10 {
        width: auto;
        margin: 10px 0;
    }

    #locations .col-md-10 h4 {
        font-size: 30px;
    }

    #locations .col-md-10 h2 {
        font-size: 20px;
    }

    #locations .col-md-10 p {
        font-size: 14px;
    }
}

/* Footer */
footer {
    background-color: #000000;
    color: white;
    text-align: center;
    padding: 20px 0;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
}

footer a {
    color: #7426c7;
    text-decoration: none;
    transition: color 0.3s;
}

footer a:hover {
    color: #c726ae;
}
