@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,600;1,500&display=swap'); /* font-family: 'Jost', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap'); /* font-family: 'Rubik', sans-serif; */

#GYMLY {
    text-align: center;
    border: 2px solid #7426c7;
    border-radius: 20px;
    padding: 20px;
    transition: transform 0.3s;
}

#GYMLY:hover {
    transform: scale(1.05);
}

#GYMLY #text1{
    font-size: 60px;
    font-family: 'Jost', sans-serif;
    font-weight: 100;
    margin: 10px 0;
}

#GYMLY #description {
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    font-weight: 100;
    margin: 10px 0;
}

a {
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    font-weight: 100;
    text-decoration: none;
    color: white;
    transition: color 0.3s, text-decoration 0.3s;
}

a:hover {
    text-decoration: underline;
    color: #7426c7;
    text-shadow: 0 0 10px #7426c7;
}

#OpenSource {
    border: 2px solid #7426c7;
    border-radius: 20px;
    padding: 20px;
    transition: transform 0.3s;
}

#OpenSource:hover {
    transform: scale(1.05);
}

#OpenSource #text1 {
    font-size: 70px !important;
    text-align: end;
}

#OpenSource #description {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Media Queries */

/* Tablets and larger devices */
@media (min-width: 768px) {
    #GYMLY #text1 {
        font-size: 55px;
    }

    #GYMLY #description {
        font-size: 18px;
    }

    a {
        font-size: 18px;
    }
}

/* Mobile devices */
@media (max-width: 767px) {
    #GYMLY {
        padding: 15px;
    }

    #GYMLY #text1 {
        font-size: 45px;
    }

    #GYMLY #description {
        font-size: 16px;
    }

    a {
        font-size: 16px;
    }

    #OpenSource #text1 {
        font-size: 40px !important;
        text-align: start;
    }

    #OpenSource #description {
        font-size: 16px;
        text-align: start;
    }


}

/* Small mobile devices */
@media (max-width: 480px) {
    #GYMLY {
        padding: 10px;
    }

    #GYMLY #text1 {
        font-size: 32px;
    }

    #GYMLY #description {
        font-size: 14px;
    }

    a {
        font-size: 14px;
    }

    #OpenSource #text1 {
        font-size: 45px !important;
        text-align: start;
    }


    a {
        font-size: 14px;
    }
}
