/* Font Imports */
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,600;1,500&display=swap'); /* font-family: 'Jost', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap'); /* font-family: 'Rubik', sans-serif; */

/* Global Styles */
body {
    font-family: 'Rubik', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Header Text 1 */
.headertext1 {
    font-size: 42px;
    font-family: 'Jost', sans-serif;
    font-weight: 900; /* Adjusted to 900 instead of 1000 */
    background-image: url("./Images/text-gif.gif");
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
}

/* Header Text 2 */
.headertext2 {
    font-family: 'Jost', sans-serif;
    font-size: 42px;
    background-image: url("./Images/gifs/dark-header.gif");
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    padding: 10px 40px; /* Adjust padding as needed */
}

/* Paragraph Text */
.textpharagraph {
    font-size: 20px;
    font-family: 'Rubik', sans-serif;
    font-weight: 300;
}

/* Contact Button */
.contact-button {
    border: none; /* Remove border */
    padding: 13px 28px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 19px;
    border-radius: 5px;
    font-weight: bold;
    background-image: linear-gradient(45deg, #7426c7, #c726ae);
    color: white;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.contact-button:hover {
    background-image: linear-gradient(45deg, #ffffff, #ffffff); /* Adjust gradient on hover */
    color: #7426c7;
}


/* LakinduMessage */
#LakinduMessage {
    font-size: 20px;
    font-family: 'Rubik', sans-serif;
    font-weight: 300;
    color: #6c757d;
}

#LakinduMessage #LakinduMessage-Row{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

#LakinduMessage img {
    border-radius: 20px; 
    padding: 10px;
    width: 90%;
    height: auto;
}





/* Navbar */
#Navbar {
    font-family: 'Outfit', sans-serif;
    font-size: 23px;
    color: white;
}

/* Tech Stack logos */
#tech-stack-logo #tech-stack-logo-text { 
    font-size: 40px;
    font-family: 'Jost', sans-serif;
    font-weight: 900; /* Adjusted to 900 instead of 1000 */
    text-align: right;
    background-image: url("./Images/text-gif.gif");
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
}

#tech-stack-logo div div img {
    max-width: 50px; /* Set a maximum width for logos */
    height: auto;
    margin: 8px; /* Adjust margin for better spacing */
}

#tech-stack-logo div div img:hover {
    filter: grayscale(100%);
}

#tech-stack-logo #tech-stack-pharagraph {
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
}
/* Clients Brands */
#clients-brands {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#clients-brands #brands-logo-text { 
    font-size: 40px;
    font-family: 'Jost', sans-serif;
    font-weight: 900;
    text-align: center;
    background-image: url("./Images/text-gif.gif");
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
}

#clients-brands .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


#clients-brands .d-flex img {
    max-width: 100%;
    height: auto;
    max-height: 80px; /* Set a maximum height for logos */
    margin: 8px; /* Adjust margin for better spacing */
    object-fit: contain; /* Maintains aspect ratio */
}

#clients-brands .d-flex img:hover {
    filter: grayscale(100%);
}

#clients-brands #paragraph {
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    color: #6c757d;
    padding-top: 20px;
}


/* Footer */
#footer .social-icons a {
    text-decoration: none;
    color: #ffffff;
    font-size: 20px;
    margin: 0 10px;
}

#footer p {
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
}

#footer .footer-links {
    padding-left: 20px;
}

#footer .footer-links h5 {
    font-size: 20px;
    font-family: 'Jost', sans-serif;
    padding-top: 10px;
}

#footer .footer-links ul li {
    list-style: none;
    padding: 5px 0;
    font-family: 'Rubik', sans-serif;
}

#footer .footer-links ul li a {
    color: #ffffff;
    text-decoration: none;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
}

#footer .footer-links ul li a:hover {
    text-decoration: underline;
}

/* Schedule Call Component */
#Shedule-Call-Component {
    background: rgba(4, 1, 1, 0.74);
    box-shadow: 0 4px 30px rgba(223, 207, 207, 0.1);
}

#Shedule-Call-Component #Shedule-Call-Component-Title {
    font-family: 'Jost', sans-serif;
    font-size: 4.6875rem; /* 75px */
    background-image: url("./Images/gifs/dark-header.gif");
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
}

/* Page Header Component */
#Page-Header-Component {
    background: rgba(4, 1, 1, 0.74);
}

#Page-Header-Component .page-title {
    font-family: 'Jost', sans-serif;
    font-size: 4.6875rem; /* 75px */
    background-image: url("./Images/gifs/dark-header.gif");
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;

    /* padding top and bottom */
    padding-top: 50px;
    padding-bottom: 10px;
   
}

#Page-Header-Component .page-subtitle {
    font-family: 'Rubik', sans-serif;
    font-size: 1.3rem; /* 16px */
    color: white;
    margin-bottom: 20px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5); /* Add shadow effect */

    /* padding top and bottom */
    padding-bottom: 60px;
    padding-left: 10px;

}

/* Progress Flow Section */
#progress-flow #progress-flow-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

#progress-flow #progress-flow-row .col-md-3 {
    margin: 0 30px;
}

#progress-flow #progress-flow-row #progress-flow-img {
    max-width: auto;
    height: 160px;
    padding: 20px;
    margin: 10px;
}

#progress-flow #progress-flow-row img {
    max-width: auto;
    height: 130px;
}

#progress-flow #progress-flow-row img:hover {
    /* zoom */
    transform: scale(1.2);

}

#progress-flow #progress-flow-row h5 {
    font-size: 24px;
    font-family: 'Jost', sans-serif;
    font-weight: 900; /* Adjusted to 900 instead of 1000 */
    text-align: center;
}

#progress-flow #progress-flow-row .lead {
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    font-weight: 300;
    text-align: center;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 3px;
    color: #7426c7;
    aspect-ratio: 1;
    border-radius: 50%;
    box-shadow: 
      19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 0px,
      19px 0     0 5px, 38px 0     0 5px, 57px 0     0 5px,
      19px 19px  0 0px, 38px 19px  0 0px, 57px 19px  0 0px;
    transform: translateX(-38px);
    animation: l26 2s infinite linear;
  }
  @keyframes l26 {
    12.5% {box-shadow: 
      19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 5px,
      19px 0     0 5px, 38px 0     0 0px, 57px 0     0 5px,
      19px 19px  0 0px, 38px 19px  0 0px, 57px 19px  0 0px}
    25%   {box-shadow: 
      19px -19px 0 5px, 38px -19px 0 0px, 57px -19px 0 5px,
      19px 0     0 0px, 38px 0     0 0px, 57px 0     0 0px,
      19px 19px  0 0px, 38px 19px  0 5px, 57px 19px  0 0px}
    50%   {box-shadow: 
      19px -19px 0 5px, 38px -19px 0 5px, 57px -19px 0 0px,
      19px 0     0 0px, 38px 0     0 0px, 57px 0     0 0px,
      19px 19px  0 0px, 38px 19px  0 0px, 57px 19px  0 5px}
    62.5% {box-shadow: 
      19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 0px,
      19px 0     0 5px, 38px 0     0 0px, 57px 0     0 0px,
      19px 19px  0 0px, 38px 19px  0 5px, 57px 19px  0 5px}
    75%   {box-shadow: 
      19px -19px 0 0px, 38px -19px 0 5px, 57px -19px 0 0px,
      19px 0     0 0px, 38px 0     0 0px, 57px 0     0 5px,
      19px 19px  0 0px, 38px 19px  0 0px, 57px 19px  0 5px}
    87.5% {box-shadow: 
      19px -19px 0 0px, 38px -19px 0 5px, 57px -19px 0 0px,
      19px 0     0 0px, 38px 0     0 5px, 57px 0     0 0px,
      19px 19px  0 5px, 38px 19px  0 0px, 57px 19px  0 0px}
  }

/* Media Query for Tablet Screens */
@media (max-width: 768px) {
    .headertext1,
    .headertext2,
    #tech-stack-logo #tech-stack-logo-text,
    #clients-brands #brands-logo-text {
        text-align: center;
    }

    .headertext1 {
        font-size: 45px;
    }

    .headertext2 {
        font-size: 45px;
        padding: 20px; /* Adjust padding for smaller screens */
    }

    .textpharagraph {
        font-size: 18px;
        text-align: center; /* Center align text */
    }

    .contact-button {
        font-size: 16px;
        padding: 10px 20px;
        display: block;
        margin: 0 auto; /* Center the button */
    }

    #tech-stack-logo div div img {
        max-width: 40px; /* Adjust max-width for smaller screens */
        margin: 3px; /* Adjust margin for smaller screens */
    }

    #clients-brands #brands-logo-text {
        font-size: 40px;
    }
}

/* Media Query for Small Mobile Screens */
@media (max-width: 480px) {
    .headertext1,
    .headertext2,
    #tech-stack-logo #tech-stack-logo-text,
    #clients-brands #brands-logo-text {
        text-align: center;
    }

    .headertext1 {
        font-size: 36px;
    }

    .headertext2 {
        font-size: 36px;
        padding: 10px;
    }

    .textpharagraph {
        font-size: 16px; /* Adjusted for smaller screens */
        text-align: center; /* Center align text */
    }

    .contact-button {
        font-size: 14px;
        padding: 8px 16px;
        display: block;
        margin: 0 auto; /* Center the button */
    }

    #tech-stack-logo div div img {
        max-width: 30px; /* Adjust max-width for smaller screens */
        margin: 3px; /* Adjust margin for smaller screens */
    }

    #clients-brands #brands-logo-text {
        font-size: 35px;
    }
}

/* Media Query for Standard Screen Sizes */
@media (min-width: 769px) {
    #clients-brands img {
        height: 150px; /* Standard size on larger screens */
    }
}
