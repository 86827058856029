/* Common styles for both screen and print */
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,600;1,500&display=swap'); /* font-family: 'Jost', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap'); /* font-family: 'Rubik', sans-serif; */

/* Styles for screen */
@media screen {
    #careers-benefits {
        border-radius: 20px;
    }

    #careers-benefits .c1,
    #careers-benefits .c2,
    #careers-benefits .c3 {
        padding: 20px;
        border-radius: 20px;
        margin: 10px;
        box-shadow: 0 0 10px 0 rgba(114, 7, 181, 0.614);
        background-image: linear-gradient(45deg, #7426c7, #c726ae);
    }

    #careers-benefits .row .col-md-3 h5 {
        font-size: 25px;
        font-family: 'Jost', sans-serif;
        font-weight: 900;
        color: white;
    }

    #careers-benefits .row .col-md-3 p {
        font-size: 16px;
        font-family: 'Rubik', sans-serif;
        font-weight: 300;
        color: white;
    }

    /* Additional styles specific to rooster-widget.css for screen */
    .rooster-search {
        padding: 0.6rem 1rem;
        flex: 1;
    }
    .rooster-job {
        padding: 1.4rem 0rem;
    }
    .rooster-job:not(:last-child) {
        border-bottom: 1px solid #EEEEEE;
    }
    .rooster-job a {
        color: rgb(10,10,10);
        text-decoration: none;
    }
    .rooster-job h3 {
        padding: 0;
        margin: 0 0 0.4rem 0;
        font-size: 1.2rem;
        font-weight: 600;
        text-decoration: none;
    }
    .rooster-job h4 {
        padding: 0;
        margin: 0;
        display: block;
        font-size: 0.9rem;
        font-weight: 600;
    }
    .rooster-department-header {
        font-size: 1.4rem;
        margin: 0px;
        padding: 2rem 0rem 0rem;
        color: rgb(21, 178, 211);
    }
    .rooster-department-header h3 {
        margin-bottom: 0px;
        font-weight: 700;
    }
    .rooster-job .data-row {
        padding: 0rem 0rem 0.4rem 0rem;
        font-size: 0.924rem;
    }
    .rooster-job .inline-value {
        padding-right: 0.6rem;
        margin-right: 0.6rem;
        color: rgba(10,10,10,1);
    }
    .rooster-job .inline-value:not(:last-child) {
        border-right: 1px solid rgba(10,10,10,0.1);
    }
}

/* Styles for print */
@media print {
    body {
        font-family: 'Rubik', sans-serif; /* Example: set a default font for print */
    }

    /* Additional print styles as needed */
    #careers-benefits {
        /* Example: hide specific elements or adjust layout for print */
    }

    .rooster-job {
        page-break-inside: avoid; /* Example: prevent job listings from breaking across pages */
    }

    /* Example: adjust font sizes, colors, and other details for print */
    .rooster-job h3 {
        font-size: 1.1rem;
    }

    .rooster-job h4 {
        font-size: 0.9rem;
    }

    .rooster-department-header {
        font-size: 1.2rem;
        color: rgb(0, 0, 0); /* Adjust text color for print readability */
    }

    .rooster-job .data-row {
        font-size: 0.8rem;
    }
}
