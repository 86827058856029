@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,600;1,500&display=swap'); /* font-family: 'Jost', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap'); /* font-family: 'Rubik', sans-serif; */

#terms-and-conditions {
    font-family: 'Rubik', sans-serif;
    color: #333;
  }
  
  #terms-and-conditions h1, 
  #terms-and-conditions h2 {
    font-family: 'Jost', sans-serif;
    margin-bottom: 20px;
  }
  
  #terms-title {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  #effective-date {
    font-size: 1rem;
  }
  
  section {
    margin-bottom: 30px;
  }
  
  #introduction-title,
  #agreement-title,
  #cookies-title,
  #license-title,
  #user-comments-title,
  #hyperlinking-title,
  #iframes-title,
  #content-liability-title,
  #your-privacy-title,
  #reservation-rights-title,
  #removal-links-title,
  #disclaimer-title {
    font-size: 1.75rem;
  }
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  
  a {
    color: #7426c7;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  address {
    font-style: normal;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    #terms-and-conditions {
      padding: 0 15px;
    }
  
    #terms-title {
      font-size: 2rem;
    }
  
    #introduction-title,
    #agreement-title,
    #cookies-title,
    #license-title,
    #user-comments-title,
    #hyperlinking-title,
    #iframes-title,
    #content-liability-title,
    #your-privacy-title,
    #reservation-rights-title,
    #removal-links-title,
    #disclaimer-title {
      font-size: 1.5rem;
    }
  
    ul {
      margin-left: 15px;
    }
  
    ul li {
      margin-bottom: 8px;
    }
  
    address {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    #terms-title {
      font-size: 1.5rem;
    }
  
    #introduction-title,
    #agreement-title,
    #cookies-title,
    #license-title,
    #user-comments-title,
    #hyperlinking-title,
    #iframes-title,
    #content-liability-title,
    #your-privacy-title,
    #reservation-rights-title,
    #removal-links-title,
    #disclaimer-title {
      font-size: 1.25rem;
    }
  
    address {
      font-size: 0.8rem;
    }
  }
  