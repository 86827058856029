@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,600;1,500&display=swap'); /* font-family: 'Jost', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap'); /* font-family: 'Rubik', sans-serif; */

#contact-form {
    /* Center content horizontally */
    text-align: center;
    margin: 20px auto;
    max-width: 600px; /* Adjust as needed */
    border: 1px solid #7426c7;
}


#contact-form #text1 {
    font-size: 40px;
    font-family: 'Jost', sans-serif;
    font-weight: 100;
    background-image: url("../Images/text-gif.gif");
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
}

#contact-form label {
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
}

#contact-form .input-field {
    width: 100%;
    padding: 10px;
    margin-top: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

#contact-form .input-field:focus {
    border-color: #7426c7;
}

#contact-form .input-field::placeholder {
    color: #ccc;
}

#contact-form .input-field:focus::placeholder {
    color: #7426c7;
}

#contact-form #ProjectType {
    width: 100%;
}

#contact-form .form-check-label {
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
}

#contact-form .form-check-input:checked {
    background-color: #7426c7;
}

#contact-form textarea {
    width: 100%;
    padding: 20px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
}

#contact-form textarea:focus {
    border-color: #7426c7;
}

#contact-form button {
    border: 2px solid; /* Remove border color */
    padding: 13px 28px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 19px;
    border-radius: 5px;
    font-family: 'Jost', sans-serif;

    /* Create the gradient for the border */
    background-image: linear-gradient(45deg, #7426c7, #c726ae);
    border-color: transparent; /* Hide the original border */
    color: white; /* Set text color */

    /* Transition for hover state */
    transition: background-color 0.5s, color 0.5s;
}


#contact-form button:hover {
    background-image: none;
    background-color: #ffffff;
    color: #7426c7;
    border-color: #7426c7;

    /* Transition for hover state */
    transition: background-color 0.5s, color 0.5s;
}

#contact-form button:active {
    background-color: #7426c7;
    color: white;
}

#contact-form button:focus {
    outline: none;
}

#contactform-info .headertext2 {
    font-size: 60px!important;
}

#contactform-info .col-md-4 {
    padding: 20px;
    border: 1px solid #7426c7;
    padding-top: 30px;
    padding-bottom: 30px;
}

#contactform-info h3 {
    font-family: 'Jost', sans-serif;
    font-size: 35px;
    font-weight: 100;
    color: white;
    margin-bottom: 10px;
}

#contactform-info p {
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    color: white;
    padding: 5px;
}

#contactform-info a {
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    color: white!important;
}

#contactform-info a:hover {
    color: #ffffff!important;
}


/* Media Queries */

@media (max-width: 768px) {
    #contact-form {
        /* Adjust styles for smaller screens */
        max-width: 100%;
    }

    #contact-form #text1 {
        font-size: 32px; /* Adjust font size for smaller screens */
    }

    #contact-form label {
        font-size: 18px; /* Adjust font size for smaller screens */
    }

    #contact-form .input-field {
        padding: 8px; /* Adjust padding for smaller screens */
    }

    #contact-form .input-field::placeholder {
        font-size: 14px; /* Adjust font size for smaller screens */
    }

    #contact-form .form-check-label {
        font-size: 14px; /* Adjust font size for smaller screens */
    }

    #contact-form textarea {
        padding: 15px; /* Adjust padding for smaller screens */
    }

    #contact-form button {
        padding: 10px 20px; /* Adjust padding for smaller screens */
        font-size: 16px; /* Adjust font size for smaller screens */
    }

    #contactform-info .headertext2 {
        font-size: 40px!important;
    }

    #contactform-info .col-md-4 {
        padding: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    #contactform-info h3 {
        font-size: 30px;
    }

    #contactform-info p {
        font-size: 16px;
    }

    #contactform-info a {
        font-size: 18px;
    }

}

@media (max-width: 480px) {
    #contact-form {
        /* Further adjustments for very small screens */
    }

    #contact-form #text1 {
        font-size: 24px; /* Adjust font size for very small screens */
    }

    #contact-form label {
        font-size: 16px; /* Adjust font size for very small screens */
    }

    #contact-form .input-field {
        padding: 6px; /* Adjust padding for very small screens */
    }

    #contact-form .input-field::placeholder {
        font-size: 12px; /* Adjust font size for very small screens */
    }

    #contact-form .form-check-label {
        font-size: 12px; /* Adjust font size for very small screens */
    }

    #contact-form textarea {
        padding: 10px; /* Adjust padding for very small screens */
    }

    #contact-form button {
        padding: 8px 16px; /* Adjust padding for very small screens */
        font-size: 14px; /* Adjust font size for very small screens */
    }

    #contactform-info .headertext2 {
        font-size: 30px!important;
    }

    #contactform-info .col-md-4 {
        padding: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    #contactform-info h3 {
        font-size: 25px;
    }

    #contactform-info p {
        font-size: 14px;
    }

    #contactform-info a {
        font-size: 16px;
    }
}