/* Common styles for both screen and print */
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,600;1,500&display=swap'); /* font-family: 'Jost', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap'); /* font-family: 'Rubik', sans-serif; */

/* Global Styles */
body {
    font-family: 'Rubik', sans-serif;
    margin: 0;
    padding: 0;
    color: #333;
    line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Jost', sans-serif;
}

h1 {
    font-size: 2.5rem; /* 40px */
}

h2 {
    font-size: 2rem; /* 32px */
}

h3 {
    font-size: 1.75rem; /* 28px */
}

h4 {
    font-size: 1.5rem; /* 24px */
}

h5 {
    font-size: 1.25rem; /* 20px */
}

h6 {
    font-size: 1rem; /* 16px */
}

p {
    font-size: 1rem; /* 16px */
    margin-bottom: 1rem; /* Space below paragraphs */
}

a {
    color: #007bff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}



.row {
    display: flex;
    flex-wrap: wrap;
    margin: -0.5rem;
}

.col {
    flex: 1;
    padding: 0.5rem;
}

#readmore {
    border: solid; /* Remove border */
    padding: 13px 28px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    font-family: 'Rubik', sans-serif;
    border-radius: 5px;
    font-weight: bold;
    background-image: linear-gradient(45deg, #7426c7, #c726ae);
    color: white;
    transition: background-color 0.3s ease, color 0.3s ease;
}

#readmore:hover {
    border-style: solid;
    background-image: linear-gradient(45deg, #ffffff, #ffffff); /* Adjust gradient on hover */
    color: #7426c7;
}

.card {
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 1rem;
    margin: 1rem;
}

/* Print Styles */
@media print {
    body {
        font-family: 'Rubik', sans-serif;
        color: #000;
    }

    .container {
        width: 100%;
        max-width: none;
        margin: 0;
        padding: 0;
    }

    .row {
        display: block;
        margin: 0;
    }

    .col {
        padding: 0;
        width: 100%;
    }

    h1, h2, h3, h4, h5, h6 {
        page-break-after: avoid;
    }

    a {
        color: #000;
    }

    a:after {
        content: " (" attr(href) ")";
    }
}
