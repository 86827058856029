@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,600;1,500&display=swap'); /* font-family: 'Jost', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap'); /* font-family: 'Rubik', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); /* font-family: 'Roboto', sans-serif; */

/* Service Header */
#service-header #text1 {
    font-family: 'Jost', sans-serif;
    font-size: 4.6875rem; /* 75px */
    background-image: url("../Images/gifs/dark-header.gif");
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
}

/* Service Categories */
#service-catogories {
    background-image: url("../Images/gifs/bg-2.gif");
    background-size: cover;
    padding: 20px 40px;
    border-radius: 16px;
}

#service-catogories #text1 {
    font-family: 'Jost', sans-serif;
    font-size: 2.8125rem; /* 45px */
    color: white;
    margin-bottom: 20px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

#service-catogories #text2 {
    font-family: 'Rubik', sans-serif;
    font-size: 1rem; /* 16px */
    color: white;
    margin-bottom: 20px;
}

#service-catogories .col-md-4 {
    margin: 10px 0;
}

.service-catogory {
    background: rgba(0, 0, 0, 0.74);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.9px);
    -webkit-backdrop-filter: blur(7.9px);
    border: 2px solid rgb(255, 255, 255);
    padding: 30px 20px;
    margin: 5px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.service-catogory h3 {
    font-family: 'Jost', sans-serif;
    font-size: 1.75rem; /* 28px */
    margin-bottom: 10px;
    color: aliceblue;
    font-weight: 600;
}

.service-catogory .icon {
    font-size: 80px;
    color: aliceblue;
}

.service-catogory p {
    font-family: 'Roboto', sans-serif;
    font-size: 1.125rem; /* 18px */
    font-weight: 400;
    margin-top: 10px;
    color: aliceblue;
}

#product-engineering {
    padding: 20px 40px;
    border-radius: 16px;
}

#product-engineering #bigHeader {
    font-family: 'Jost', sans-serif;
    font-size: 65px; /* Adjusted size */
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.2); /* Subtle outer glow */
    color: #000000; /* Ensure the text color is set for visibility */
}

#product-engineering #bigHeader #part1 {
    font-size: 70px;
    background-image: url("../Images/gifs/bg-2.gif");
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
}

#product-engineering #product-pharagraph {
    font-family: 'Rubik', sans-serif;
    font-size: 30px;
    font-weight: bolder;
    margin-top: 20px;
}

#product-engineering .col-md-5 {
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.9px);
    -webkit-backdrop-filter: blur(7.9px);
    border: 2px solid rgb(255, 255, 255);
    margin: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* add some shadow */
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

#product-engineering #col1 {
    background: rgba(82, 22, 134, 0.831);
}

#product-engineering #col2 {
    background: rgba(146, 25, 25, 0.74);
}

#product-engineering #col3 {
    background: rgba(0, 0, 0, 0.74);
}

#product-engineering #col4 {
    background: rgba(14, 59, 172, 0.74);
}

#product-engineering .col-md-5:hover {
    transform: scale(1.05);
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.7);
}

#product-engineering h1 {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    font-family: 'Jost', sans-serif;
}

#product-engineering p {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 400;
    margin-top: 10px;
    color: aliceblue;
}

/* Media Queries */
/* Mobile Phones */
@media (max-width: 576px) {
    #service-catogories {
        background-image: url("../Images/gifs/dark-header.gif");
    }

    #service-header #text1 {
        font-size: 2.5rem; /* 40px */
    }

    #service-catogories #text1 {
        font-size: 2rem; /* 32px */
    }

    #service-catogories #text2 {
        font-size: 0.875rem; /* 14px */
    }

    .service-catogory h3 {
        font-size: 1.5rem; /* 24px */
    }

    .service-catogory .icon {
        font-size: 60px;
    }

    .service-catogory p {
        font-size: 1rem; /* 16px */
    }

    #product-engineering #bigHeader {
        font-size: 30px; /* 40px */
    }

    #product-engineering #bigHeader #part1 {
        font-size: 50px;
    }

    #product-engineering #product-pharagraph {
        font-size: 20px;
    }
}

/* Tablets */
@media (min-width: 577px) and (max-width: 992px) {
    #service-header #text1 {
        font-size: 3.125rem; /* 50px */
    }

    #service-catogories #text1 {
        font-size: 2.25rem; /* 36px */
    }

    #service-catogories #text2 {
        font-size: 1rem; /* 16px */
    }

    .service-catogory h3 {
        font-size: 1.625rem; /* 26px */
    }

    .service-catogory .icon {
        font-size: 70px;
    }

    .service-catogory p {
        font-size: 1.0625rem; /* 17px */
    }

    #product-engineering #bigHeader {
        font-size: 45px; /* 45px */
    }

    #product-engineering #bigHeader #part1 {
        font-size: 60px;
    }

    #product-engineering #product-pharagraph {
        font-size: 25px;
    }
}

/* Larger Screens */
@media (min-width: 993px) {
    #service-header #text1 {
        font-size: 4.6875rem; /* 75px */
    }

    #service-catogories #text1 {
        font-size: 2.8125rem; /* 45px */
    }

    #service-catogories #text2 {
        font-size: 1rem; /* 16px */
    }

    .service-catogory h3 {
        font-size: 1.75rem; /* 28px */
    }

    .service-catogory .icon {
        font-size: 80px;
    }

    .service-catogory p {
        font-size: 1.125rem; /* 18px */
    }

    #product-engineering #bigHeader {
        font-size: 50px; /* 45px */
    }

    #product-engineering #bigHeader #part1 {
        font-size: 64px;
    }

    #product-engineering #product-pharagraph {
        font-size: 30px;
    }
}
