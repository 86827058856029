@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,600;1,500&display=swap'); /* font-family: 'Jost', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap'); /* font-family: 'Rubik', sans-serif; */

/* privacy-policy.css */

#privacy-policy {
    font-family: 'Rubik', sans-serif;
    color: #333;
  }
  
  #privacy-policy h1,
  #privacy-policy h2 {
    font-family: 'Jost', sans-serif;
    margin-bottom: 20px;
  }
  
  #policy-title {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  #effective-date {
    font-size: 1rem;
  }
  
  section {
    margin-bottom: 30px;
  }
  
  #introduction-title,
  #information-we-collect-title,
  #how-we-use-information-title,
  #information-sharing-title,
  #security-title,
  #changes-to-policy-title,
  #contact-us-title {
    font-size: 1.75rem;
  }
  
  #principles-list,
  #information-types-list,
  #use-cases-list,
  #sharing-cases-list {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  #principles-list li,
  #information-types-list li,
  #use-cases-list li,
  #sharing-cases-list li {
    margin-bottom: 10px;
  }
  
  a {
    color: #7426c7;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  address {
    font-style: normal;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    #privacy-policy {
      padding: 0 15px;
    }
  
    #policy-title {
      font-size: 2rem;
    }
  
    #introduction-title,
    #information-we-collect-title,
    #how-we-use-information-title,
    #information-sharing-title,
    #security-title,
    #changes-to-policy-title,
    #contact-us-title {
      font-size: 1.5rem;
    }
  
    #principles-list,
    #information-types-list,
    #use-cases-list,
    #sharing-cases-list {
      margin-left: 15px;
    }
  
    #principles-list li,
    #information-types-list li,
    #use-cases-list li,
    #sharing-cases-list li {
      margin-bottom: 8px;
    }
  
    address {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    #policy-title {
      font-size: 1.5rem;
    }
  
    #introduction-title,
    #information-we-collect-title,
    #how-we-use-information-title,
    #information-sharing-title,
    #security-title,
    #changes-to-policy-title,
    #contact-us-title {
      font-size: 1.25rem;
    }
  
    address {
      font-size: 0.8rem;
    }
  }
  
  